<template>
  <div class="chat">
    <van-image width="100%" :src="require('./img/img.png')" />
    <div class="close" @click="onClickLeft"></div>
    <div class="bg"></div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.chat {
  position: relative;

  .close {
    width: 5.25rem;
    height: 5.25rem;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 5;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>